import { getAccountPermalink } from '~/lib'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const localePath = useLocalePath()
  const { refreshUser, isLoggedIn } = useUser()

  await refreshUser()

  if (isLoggedIn.value) return navigateTo({ path: localePath(getAccountPermalink()) })
})
